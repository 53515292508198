
export const validateUniquePhoneNumberUri = process.env.REACT_APP_URI_PHONE_NUMBER_UNIQUE_VALIDATION
export const validateUniqueRussianPassportSeriesNumUri = process.env.REACT_APP_URI_RUSSIAN_PASSPORT_SERIES_NUM_UNIQUE_VALIDATION

export const countriesPhoneCodesUri = process.env.REACT_APP_URI_COUNTRIES_PHONE_NUMBERS

export const patientsUri = process.env.REACT_APP_URI_PATIENTS

export const productsUri = process.env.REACT_APP_URI_PRODUCTS


