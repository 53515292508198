import Button from "@mui/material/Button";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {useNavigate} from "react-router-dom";
import StickyPageHeader from "../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import {patientsUri} from "../../utils/uris";
import ServerDataGrid from "../../components/ServerDataGrid/ServerDataGrid";

const PatientList = () => {
    const navigate = useNavigate();
    const columns = [
        {
            field: "surname",
            headerName: "Фамилия",
            flex: 110,
            // valueGetter: ({ row }) => row.person.surname
        },
        {
            field: "name",
            headerName: "Имя",
            flex: 110,
            // valueGetter: ({ row }) => row.person.name
        },
        {
            field: "patronymic",
            headerName: "Отчество",
            flex: 120,
            // valueGetter: ({ row }) => row.person.patronymic
        },
        {
            field: "birth_date",
            headerName: "Дата рождения",
            flex: 100,
            type: "date",
            valueGetter: ({value}) => new Date(value),
        },
        {
            field: "primary_phone_number",
            headerName: "Номер телефона",
            flex: 100,
            // valueGetter: ({ row }) => row.person.primary_phone_number
        },
    ]

    const handleRowClick = (params, event, details) => {
        navigate(`/patients/${params.row.id}`)
    }

    const addPatientButton = <Button size="medium"
                                     variant="contained"
                                     startIcon={<PersonAddAlt1Icon/>}
                                     onClick={() => {
                                         navigate('add')
                                     }}>
        Добавить пациента
    </Button>

    return (
        <>
            <StickyPageHeader title="Справочник пациентов" button={addPatientButton}/>
            <ServerDataGrid
                endpoint={`${patientsUri}`}
                columns={columns}
                rowClickHandler={handleRowClick}
            />
        </>
    );
};

export default PatientList;