import {lazy, Suspense, useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {Skeleton} from "@mui/material";
import useData from "../../customHooks/useData";
import SearchField from "../Forms/FormFields/SearchField";

const DataGrid = lazy(
    () => import('@mui/x-data-grid').then(module => ({default: module.DataGrid}))
);

const dataGridFallback = (<Grid container flex flexDirection="column" gap={1}>
    {Array(12).fill(0).map((_, i) => {
        return <Skeleton key={i} variant="rounded" width="100%" height={42} component="div"/>
    })}
</Grid>)

const ServerDataGrid = ({
                            endpoint,
                            columns = [],
                            rowClickHandler = (params, event, details) => {
                            },
                            pageSizeOptions = [10, 20, 50, 100]
                        }) => {
    const {data, isLoading, setUrlParams} = useData({
        endpoint: endpoint
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [dataGridPaginationModel, setDataGridPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });


    const rows = data?.results || []
    const rowCount = data?.count || 0

    useEffect(() => {
        setUrlParams(prevState => {
            return {
                ...prevState,
                search: searchQuery,
                page: dataGridPaginationModel.page + 1,
                size: dataGridPaginationModel.pageSize
            }
        })
    }, [dataGridPaginationModel, searchQuery]);

    const sortingHandler = (arrayOfSortedFields = []) => {
        // `arrayOfSortedFields` looks smth like [{"field": "name", "sort": "desc"}]
        // since using free version, array contains one object at index 0.
        const sortField = arrayOfSortedFields[0]; // Handle single sort explicitly
        // translate for DRF
        // E.g. {"field": "name", "sort": "desc"} to "-name"
        setUrlParams(prevState => ({
                ...prevState,
                // if no sortFiled then null
                ordering: !!sortField ? `${sortField.sort === "asc" ? "" : "-"}${sortField.field}` : null
            })
        );
    };

    const searchChangeHandler = (e) => {
        setSearchQuery(e.target.value)
    };

    return (
        <Grid container flex flexDirection="column" gap={2}>
            <Grid item container xs={5}>
                <SearchField queryChangeHandler={searchChangeHandler}
                             searchQuery={searchQuery}
                             fullWidth
                             clearHandler={() => setSearchQuery("")}/>
            </Grid>
            <Suspense fallback={dataGridFallback}>
                <DataGrid sx={{
                    "& .MuiDataGrid-row:hover": {
                        cursor: 'pointer'
                    }
                }}
                          loading={isLoading}
                          autoHeight
                          rows={rows}
                          columns={columns}
                          paginationMode="server"
                          onPaginationModelChange={setDataGridPaginationModel}
                          pageSizeOptions={pageSizeOptions}
                          rowCount={rowCount}
                          paginationModel={dataGridPaginationModel}
                          onRowClick={rowClickHandler}
                          sortingMode="server"
                          onSortModelChange={sortingHandler}
                          disableSelectionOnClick
                          slotProps={{
                              pagination: {
                                  labelDisplayedRows: ({from, to, count, page}) => {
                                      return '' + from + '-' + to + ' из ' + count
                                  }
                              }
                          }}
                />
            </Suspense>
        </Grid>
    );
};

export default ServerDataGrid;