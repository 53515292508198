import React, {useState} from "react";

const AuthContext = React.createContext({
        accessToken: '',
        refreshToken: '',
        accessTokenLifetime: '',
        isLoggedIn: false,
        authHeader: {"Authorization": ""},
        login: (accessToken, refreshToken, accessTokenLifetime) => {
        },
        logout: () => {
        }
    }
)

export const AuthContextProvider = (props) => {

    const initAccessToken = localStorage.getItem('accessToken')
    const initRefreshToken = localStorage.getItem('refreshToken')
    const initAccessTokenLifetime = localStorage.getItem('accessTokenLifetime')

    const [accessToken, setAccessToken] = useState(initAccessToken);
    const [refreshToken, setRefreshToken] = useState(initRefreshToken);
    const [accessTokenLifetime, setAccessTokenLifetime] = useState(initAccessTokenLifetime);

    const userIsLoggedIn = !!initAccessToken;
    // const userIsLoggedIn = true;

    const loginHandler = (accessToken, refreshToken, accessTokenLifetime) => {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setAccessTokenLifetime(accessTokenLifetime);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('accessTokenLifetime', accessTokenLifetime);
    };

    const logoutHandler = () => {
        setAccessToken(null);
        setRefreshToken(null);
        setAccessTokenLifetime(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessTokenLifetime');
    };

    const contextValue = {
        accessToken: accessToken,
        refreshToken: refreshToken,
        isLoggedIn: userIsLoggedIn,
        accessTokenLifetime: accessTokenLifetime,
        authHeader: {"Authorization": "Bearer " + accessToken},
        login: loginHandler,
        logout: logoutHandler
    };


    return <AuthContext.Provider value={contextValue}>
        {props.children}
    </AuthContext.Provider>
};

export default AuthContext;