import React from 'react';

const IndexPage = () => {
    return (
        <div>
            Тут теперь не пусто
        </div>
    );
};

export default IndexPage;