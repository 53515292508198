import React from 'react';
import {useParams} from "react-router-dom";
import useData from "../../../customHooks/useData";
import {productsUri} from "../../../utils/uris";
import Typography from "@mui/material/Typography";
import Components from "./Components";

const ProductDetailPage = () => {

    const [components, setComponents] = React.useState([]);

    const params = useParams();
    const {
        data: productData
    } = useData({
        endpoint: `${productsUri}products/${params.productId}/`,
    })
    return (
        <div>
            <div>
                <Typography>
                    Main Data
                </Typography>
                <pre>
            {JSON.stringify(productData, null, 2)}
            </pre>
            </div>
            <div>
                {/*<Components componentsUrl={productData?.links.Components}/>*/}
            </div>
        </div>
    );
};

export default ProductDetailPage;