import React from 'react';
import {useRouteError} from "react-router-dom";

const ErrorPage = () => {
    const error = useRouteError();
    const {status, statusText, data} = error;
    console.error(error);
    return (
        <div>
            <div>
                Ошибочка!
            </div>
            <p>
                {`${status}: ${statusText}`}
            </p>
            <p>
                {data}
            </p>
        </div>
    );
};

export default ErrorPage;