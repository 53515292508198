import React from 'react';
import Grid from "@mui/material/Grid";
import ControlledTextField from "../FormFields/ControlledTextField";
import ControlledDateInput from "../FormFields/ControlledDateInput";
import AutocompleteField from "../FormFields/AutocompleteField";
import {useFormContext} from "react-hook-form";
import useData from "../../../customHooks/useData";
import Typography from "@mui/material/Typography";
import {requiredRule} from "../defaults/formsDefaults";

const isDiscountEqualToValue = (option, value) => {
    return option.title === value.title
}

const DiscountDataForm = () => {

    const {setValue} = useFormContext();

    const {data: options} = useData({
        endpoint: "api/med/patient-discount-types/"
    })

    const discountChangeHandler = (value) => {
        if (value) {
            setValue(
                "class_type",
                value.id
            )
        }
    }

    return (
        <Grid container flex flexDirection="column" gap={2}>
            <AutocompleteField fieldLabel="Тип скидки"
                               changeHandler={discountChangeHandler}
                               options={options}
                               isOptionEqualToValue={isDiscountEqualToValue}
                               getOptionLabel={(option) => option.title}
                               fullWidth
                               required
                               autoComplete='off'/>
            <Typography>
                Срок действия
            </Typography>
            <Grid item container
                  flexDirection="row"
                  gap={2}
                  justifyContent="space-between"
                  alignItems="center">
                <Grid item xs={5}>
                    <ControlledDateInput fieldName="from_date"
                                         label="С даты"
                                         rules={requiredRule}/>
                </Grid>
                <Grid item pb={2}>
                    —
                </Grid>
                <Grid item xs={5}>
                    <ControlledDateInput fieldName="thru_date"
                                         label="По дату"/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DiscountDataForm;