import React from 'react';
import StickyPageHeader from "../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import {productsUri} from "../../utils/uris";
import {useNavigate} from "react-router-dom";
import ServerDataGrid from "../../components/ServerDataGrid/ServerDataGrid";
import NotRealizedPage from "../../components/NotRealizedPage";

const Prices = () => {

    const navigate = useNavigate();
    const columns = [
        {
            field: "name",
            headerName: "Наименование",
            flex: 280,
            // valueGetter: ({ row }) => row.person.surname
        },
        {
            field: "product_type",
            headerName: "Тип",
            flex: 280,
            // valueGetter: ({ row }) => row.person.surname
        },
        {
            field: "actual_price",
            headerName: "Цена",
            flex: 280,
            valueGetter: ({row}) => row.actual_price.price || "Б/ц"
        }
    ];

    const handleRowClick = (params, event, details) => {
        navigate(`/prices/${params.row.id}`)
    }

    return (
        <>
            <StickyPageHeader title="Справочник услуг"/>
            <NotRealizedPage/>
        </>
    );
};

export default Prices;