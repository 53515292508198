import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextField from "@mui/material/TextField";
import { Autocomplete, Skeleton } from "@mui/material";
import PropTypes from "prop-types";

const AutocompleteField = ({
                               options,
                               preSelectedOption,
                               changeHandler,
                               getOptionLabel,
                               isOptionEqualToValue,
                               renderOption,
                               slotProps,
                               open = false,
                               fieldLabel,
                               ...other
                           }) => {

    const [selectedOption, setSelectedOption] = useState( preSelectedOption )

    const [optionsList, setOptionsList] = useState( [] )

    useEffect( () => {
        if (options) {
            setOptionsList( options )
        }
    }, [options] )

    const memoizedOnChange = useCallback(
        (event, value, reason, details) => {
            changeHandler( value );
            setSelectedOption( value )
        },
        [changeHandler]
    );

    const field = useMemo( () => {
        if (!optionsList) {
            return <Skeleton
                variant="rounded"
                sx={ {
                    minHeight: 56,
                    width: "100%",
                    height: "74%"
                } }/>
        }
        return <Autocomplete
            options={ optionsList }
            value={ selectedOption }
            disableClearable
            onChange={ memoizedOnChange }
            renderOption={ renderOption }
            getOptionLabel={ getOptionLabel }
            isOptionEqualToValue={ isOptionEqualToValue }
            noOptionsText="Нет вариантов"
            slotProps={ slotProps }
            renderInput={ (params) => {
                return <TextField { ...params }
                                  label={ fieldLabel }
                                  { ...other }
                />
            } }
        />

    }, [
        optionsList, selectedOption, memoizedOnChange,
        renderOption, getOptionLabel, isOptionEqualToValue,
        slotProps, fieldLabel, other
    ] )

    return <>{ field }</>
};

export default React.memo( AutocompleteField );

AutocompleteField.propTypes = {
    options: PropTypes.array,
    preSelectedOption: PropTypes.object.isRequired,
    changeHandler: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    isOptionEqualToValue: PropTypes.func.isRequired,
    fieldLabel: PropTypes.string.isRequired,
};